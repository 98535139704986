const animationData = {
  v: '5.6.9',
  fr: 29.9700012207031,
  ip: 0,
  op: 450.000018328876,
  w: 400,
  h: 400,
  nm: 'porquinho 404',
  ddd: 0,
  assets: [],
  layers: [{
    ddd: 0,
    ind: 1,
    ty: 4,
    nm: 'rachado 2',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: -248, ix: 10 }, p: { a: 0, k: [163.12, 204.26, 0], ix: 2 }, a: { a: 0, k: [27.875, 24, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], v: [[40.75, 38.5], [38.75, 34.75], [32.738, 36.335], [35.771, 28.114], [27.34, 23.608], [30.919, 16.191], [23.979, 15.04]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.6, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Forma 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: { a: 0, k: 0, ix: 1 },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 40, s: [0],
        }, { t: 60.0000024438501, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Aparar caminhos 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 2,
    ty: 4,
    nm: 'rachado 1',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [200, 200, 0], ix: 2 }, a: { a: 0, k: [0, 0, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], v: [[40.75, 38.5], [38.75, 34.75], [33.75, 33.5], [37.75, 23], [24.5, 21.25], [23.5, 15.25], [15, 17.5], [15, 9.5]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.6, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Forma 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: { a: 0, k: 0, ix: 1 },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 10, s: [0],
        }, { t: 30.0000012219251, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Aparar caminhos 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 3,
    ty: 4,
    nm: 'quebrado frente traço',
    parent: 4,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [22.492, 24.381, 0], ix: 2 }, a: { a: 0, k: [22.492, 24.381, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], o: [[0.836, -29.237], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], v: [[-20.552, 19.967], [-1.695, -22.441], [3.67, -20.09], [2.141, -16.325], [11.214, -14.818], [8.251, -6.508], [15.987, 2.675], [2.362, 19.677], [-3.977, 16.61], [-9.006, 22.441], [-15.455, 17.485]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [22.492, 24.381], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: { a: 0, k: 0, ix: 1 },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [34],
        }, { t: 20.0000008146167, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Aparar caminhos 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 4,
    ty: 4,
    nm: 'quebrado frente cor',
    parent: 6,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 },
      r: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [0],
        }, {
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 30, s: [-62],
        }, {
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 35, s: [-94],
        }, { t: 40.0000016292334, s: [-111] }],
        ix: 10,
      },
      p: {
        a: 1,
        k: [{
          i: { x: 1, y: 1 }, o: { x: 0.594, y: 0 }, t: 20, s: [39.078, 40.78, 0], to: [0, 12, 0], ti: [0, -11.167, 0],
        }, {
          i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 30, s: [39.078, 112.78, 0], to: [0, 11.167, 0], ti: [0, -1.667, 0],
        }, {
          i: { x: 1, y: 1 }, o: { x: 0.779, y: 0 }, t: 35, s: [39.078, 107.78, 0], to: [0, 1.667, 0], ti: [0, -2.25, 0],
        }, {
          i: { x: 1, y: 1 }, o: { x: 0.333, y: 0 }, t: 40, s: [39.078, 122.78, 0], to: [0, 2.25, 0], ti: [0, -0.083, 0],
        }, {
          i: { x: 1, y: 1 }, o: { x: 0.333, y: 0 }, t: 43, s: [39.078, 121.28, 0], to: [0, 0.083, 0], ti: [0, -0.333, 0],
        }, { t: 45.0000018328876, s: [39.078, 123.28, 0] }],
        ix: 2,
      },
      a: { a: 0, k: [22.492, 24.381, 0], ix: 1 },
      s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0.836, -29.237], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]], v: [[-20.552, 19.967], [-15.455, 17.485], [-9.006, 22.441], [-3.977, 16.61], [2.362, 19.677], [15.987, 2.675], [8.251, -6.508], [11.214, -14.818], [2.141, -16.325], [3.67, -20.09], [-1.695, -22.441]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [22.492, 24.381], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm', s: { a: 0, k: 0, ix: 1 }, e: { a: 0, k: 100, ix: 2 }, o: { a: 0, k: 0, ix: 3 }, m: 1, ix: 2, nm: 'Aparar caminhos 1', mn: 'ADBE Vector Filter - Trim', hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 5,
    ty: 4,
    nm: 'patas da frente ',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [192.74, 253.905, 0], ix: 2 }, a: { a: 0, k: [27.645, 13.444, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [-1.672, 0], [0, 0], [0, 1.672], [0, 0]], o: [[0, 0], [0, 1.672], [0, 0], [1.672, 0], [0, 0], [0, 0]], v: [[-5.261, -2.267], [-5.261, 8.476], [-2.234, 11.504], [2.234, 11.504], [5.261, 8.476], [5.261, -11.504]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [48.09, 13.444], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [-1.672, 0], [0, 0], [0, 1.672], [0, 0]], o: [[0, 0], [0, 1.672], [0, 0], [1.672, 0], [0, 0], [0, 0]], v: [[-5.261, -11.504], [-5.261, 8.476], [-2.234, 11.504], [2.234, 11.504], [5.261, 8.476], [5.261, -4.909]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [7.201, 13.444], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 2',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 2,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 6,
    ty: 4,
    nm: 'porquinho',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [198.128, 256.109, 0], ix: 2 }, a: { a: 0, k: [73.422, 129.905, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [-6.245, 0.259]], o: [[0, 0], [0, 0]], v: [[-3.328, -2.005], [3.328, 1.745]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [126.511, 36.412], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 1',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [-1.175, 5.043]], o: [[0, 0], [0, 0]], v: [[-3.162, 2.392], [3.162, -2.521]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [101.104, 35.765], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 2',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 2,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0.739, 0], [0, 0], [0, 0.738], [-0.739, 0], [0, 0], [0, -0.739]], o: [[0, 0], [-0.739, 0], [0, -0.739], [0, 0], [0.739, 0], [0, 0.738]], v: [[10.301, 1.337], [-10.301, 1.337], [-11.639, 0.001], [-10.301, -1.337], [10.301, -1.337], [11.639, 0.001]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [73.9, 9.611], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 3',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 3,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [-5.361, -0.202], [0, 0]], o: [[0, 0], [5.361, 0.202], [0, 0]], v: [[-7.428, 1.877], [2.067, -5.216], [3.749, 5.418]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [89.446, 17.721], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 4',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 4,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[139.734, 58.467], [139.734, 67.92]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 5',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 5,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0]], o: [[0, 0], [0, 0]], v: [[135.008, 58.467], [135.008, 67.92]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 6',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 6,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, -7.026], [4.16, 0], [0, 7.026], [-4.16, 0]], o: [[0, 7.026], [-4.16, 0], [0, -7.026], [4.16, 0]], v: [[7.532, 0], [0, 12.722], [-7.532, 0], [0, -12.722]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [137.371, 63.193], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 7',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 7,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0], [0, 0]], v: [[-8.742, -12.722], [8.742, -12.722], [8.742, 12.722], [-8.742, 12.722]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [129.339, 63.193], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 8',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 8,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 0,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 5,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.407, 0.101]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.24, -0.096]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 10,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 15,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.407, 0.101]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.24, -0.096]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 20,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 40,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 45,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.407, 0.101]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.24, -0.096]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 50,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 55,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.407, 0.101]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.24, -0.096]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            t: 60.0000024438501,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }],
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [126.304, 43.388], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 9',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 9,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 0,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 5,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.361, -0.158]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.262, 0.154]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 10,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 15,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.361, -0.158]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.262, 0.154]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 20,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 40,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 45,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.361, -0.158]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.262, 0.154]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 50,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }, {
            i: { x: 0.667, y: 1 },
            o: { x: 0.333, y: 0 },
            t: 55,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-3.361, -0.158]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [3.262, 0.154]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0.125, 3.254]], c: true,
            }],
          }, {
            t: 60.0000024438501,
            s: [{
              i: [[0, -1.724], [1.724, 0], [0, 1.724], [-1.724, 0]], o: [[0, 1.724], [-1.724, 0], [0, -1.724], [1.724, 0]], v: [[3.121, 0], [0, 3.121], [-3.121, 0], [0, -3.121]], c: true,
            }],
          }],
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [102.033, 43.388], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 10',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 10,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 24.898]], o: [[0, 0], [-24.898, 0], [0, 0]], v: [[24.642, 22.541], [20.441, 22.541], [-24.642, -22.541]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 5, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [52.852, 97.559], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 11',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 11,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [-8.268, -10.019]], o: [[0, 0], [14.005, 0], [0, 0]], v: [[-19.494, -8.203], [-15.293, -8.203], [19.494, 8.203]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [1, 1, 1, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 5, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [93.394, 20.703], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 12',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 12,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[31.542, 0], [0, 0], [0, 31.542], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.156, 0.146], [-15.131, 0], [0, -31.542], [0, 0]], o: [[0, 0], [-31.542, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [10.219, -9.605], [31.542, 0], [0, 0], [0, 31.542]], v: [[0, 63.384], [0, 63.384], [-57.112, 6.272], [-57.112, -6.787], [-51.956, -10.273], [-46.057, -5.407], [-41.019, -10.837], [-34.805, -8.136], [-22.646, -22.221], [-30.126, -30.409], [-27.424, -38.835], [-36.458, -39.881], [-35.197, -44.766], [-39.107, -47.895], [0, -63.384], [57.112, -6.273], [57.112, 6.272]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 1, lj: 1, ml: 4, bm: 0, nm: 'Traçado 2', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 2', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [75.638, 66.521], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 13',
      np: 5,
      cix: 2,
      bm: 0,
      ix: 13,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [7.313, -1.248], [-5.723, -0.749], [12.84, 0]], o: [[0, 0], [-7.312, 1.248], [5.722, 0.749], [0, 0]], v: [[8.293, -0.383], [0.267, -5.725], [-0.624, 6.224], [-8.293, -5.883]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [10.233, 79.5], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 14',
      np: 2,
      cix: 2,
      bm: 0,
      ix: 14,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [-4.853, -2.287], [0, 0]], o: [[0, 0], [4.853, 2.287], [0, 0]], v: [[-8.184, -1.274], [3.331, -4.078], [0.71, 6.366]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [121.334, 20.751], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 15',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 15,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 7,
    ty: 4,
    nm: 'quebrado fundo',
    parent: 6,
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 70, ix: 10 }, p: { a: 0, k: [48.828, 50.53, 0], ix: 2 }, a: { a: 0, k: [22.492, 24.381, 0], ix: 1 }, s: { a: 0, k: [-100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [9.161, -10.384]], o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-5.563, 6.305]], v: [[-3.696, 28.622], [1.662, 17.484], [7.828, 14.228], [7.091, 4.184], [18.805, 2.021], [25.195, -8.2], [-6.133, -2.943]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [22.492, 24.381], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 1',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'tm',
      s: { a: 0, k: 0, ix: 1 },
      e: {
        a: 1,
        k: [{
          i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0],
        }, { t: 20.0000008146167, s: [100] }],
        ix: 2,
      },
      o: { a: 0, k: 0, ix: 3 },
      m: 1,
      ix: 2,
      nm: 'Aparar caminhos 1',
      mn: 'ADBE Vector Filter - Trim',
      hd: false,
    }],
    ip: 20.0000008146167,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }, {
    ddd: 0,
    ind: 8,
    ty: 4,
    nm: 'fundo',
    sr: 1,
    ks: {
      o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [199.337, 197.374, 0], ix: 2 }, a: { a: 0, k: [191.116, 69.974, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 },
    },
    ao: 0,
    shapes: [{
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0]], v: [[4.5, 16.747], [4.5, -20.3], [-23.966, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ind: 1,
        ty: 'sh',
        ix: 2,
        ks: {
          a: 0,
          k: {
            i: [[0, -6.494], [0, 0], [6.281, 0], [0, 0], [0, 0], [6.272, 0], [0, 0], [0, 6.281], [0, 0], [0, 0], [0, 6.076], [0, 0], [-3.35, 4.193], [0, 0], [-11.096, 0], [0, 0], [0, -6.281], [0, 0], [0, 0]], o: [[0, 0], [0, 6.076], [0, 0], [0, 0], [0, 6.068], [0, 0], [-6.281, 0], [0, 0], [0, 0], [-6.281, 0], [0, 0], [0, -6.068], [0, 0], [4.611, -5.659], [0, 0], [6.7, 0], [0, 0], [0, 0], [6.494, 0]], v: [[58.72, 26.59], [58.72, 32.445], [48.875, 41.87], [39.458, 41.87], [39.458, 58.617], [29.624, 68.034], [14.13, 68.034], [4.296, 58.404], [4.296, 41.87], [-48.875, 41.87], [-58.72, 32.445], [-58.72, 24.076], [-53.904, 10.883], [1.568, -58.608], [19.576, -68.034], [28.362, -68.034], [39.458, -57.142], [39.458, 16.747], [48.875, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 2',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'mm', mm: 1, nm: 'Mesclar caminhos 1', mn: 'ADBE Vector Filter - Merge', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [314.233, 69.974], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 1',
      np: 5,
      cix: 2,
      bm: 0,
      ix: 1,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0]], v: [[4.5, 16.747], [4.5, -20.3], [-23.966, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ind: 1,
        ty: 'sh',
        ix: 2,
        ks: {
          a: 0,
          k: {
            i: [[0, -6.494], [0, 0], [6.281, 0], [0, 0], [0, 0], [6.272, 0], [0, 0], [0, 6.281], [0, 0], [0, 0], [0, 6.076], [0, 0], [-3.35, 4.193], [0, 0], [-11.096, 0], [0, 0], [0, -6.281], [0, 0], [0, 0]], o: [[0, 0], [0, 6.076], [0, 0], [0, 0], [0, 6.068], [0, 0], [-6.281, 0], [0, 0], [0, 0], [-6.281, 0], [0, 0], [0, -6.068], [0, 0], [4.611, -5.659], [0, 0], [6.699, 0], [0, 0], [0, 0], [6.494, 0]], v: [[58.72, 26.59], [58.72, 32.445], [48.875, 41.87], [39.458, 41.87], [39.458, 58.617], [29.624, 68.034], [14.13, 68.034], [4.296, 58.404], [4.296, 41.87], [-48.875, 41.87], [-58.72, 32.445], [-58.72, 24.076], [-53.904, 10.883], [1.568, -58.608], [19.576, -68.034], [28.362, -68.034], [39.458, -57.142], [39.458, 16.747], [48.875, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 2',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'mm', mm: 1, nm: 'Mesclar caminhos 1', mn: 'ADBE Vector Filter - Merge', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [321.573, 69.974], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 2',
      np: 5,
      cix: 2,
      bm: 0,
      ix: 2,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0]], v: [[4.5, 16.747], [4.5, -20.3], [-23.965, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ind: 1,
        ty: 'sh',
        ix: 2,
        ks: {
          a: 0,
          k: {
            i: [[0, -6.494], [0, 0], [6.281, 0], [0, 0], [0, 0], [6.272, 0], [0, 0], [0, 6.281], [0, 0], [0, 0], [0, 6.076], [0, 0], [-3.349, 4.193], [0, 0], [-11.096, 0], [0, 0], [0, -6.281], [0, 0], [0, 0]], o: [[0, 0], [0, 6.076], [0, 0], [0, 0], [0, 6.068], [0, 0], [-6.281, 0], [0, 0], [0, 0], [-6.282, 0], [0, 0], [0, -6.068], [0, 0], [4.611, -5.659], [0, 0], [6.699, 0], [0, 0], [0, 0], [6.494, 0]], v: [[58.719, 26.59], [58.719, 32.445], [48.876, 41.87], [39.458, 41.87], [39.458, 58.617], [29.624, 68.034], [14.13, 68.034], [4.295, 58.404], [4.295, 41.87], [-48.875, 41.87], [-58.719, 32.445], [-58.719, 24.076], [-53.904, 10.883], [1.568, -58.608], [19.576, -68.034], [28.362, -68.034], [39.458, -57.142], [39.458, 16.747], [48.876, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 2',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'mm', mm: 1, nm: 'Mesclar caminhos 1', mn: 'ADBE Vector Filter - Merge', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0.882353001015, 0.411764735802, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [60.659, 69.974], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 3',
      np: 5,
      cix: 2,
      bm: 0,
      ix: 3,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [0, 0]], o: [[0, 0], [0, 0], [0, 0]], v: [[4.5, 16.747], [4.5, -20.3], [-23.965, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ind: 1,
        ty: 'sh',
        ix: 2,
        ks: {
          a: 0,
          k: {
            i: [[0, -6.494], [0, 0], [6.281, 0], [0, 0], [0, 0], [6.272, 0], [0, 0], [0, 6.281], [0, 0], [0, 0], [0, 6.076], [0, 0], [-3.349, 4.193], [0, 0], [-11.096, 0], [0, 0], [0, -6.281], [0, 0], [0, 0]], o: [[0, 0], [0, 6.076], [0, 0], [0, 0], [0, 6.068], [0, 0], [-6.281, 0], [0, 0], [0, 0], [-6.282, 0], [0, 0], [0, -6.068], [0, 0], [4.611, -5.659], [0, 0], [6.698, 0], [0, 0], [0, 0], [6.494, 0]], v: [[58.719, 26.59], [58.719, 32.445], [48.876, 41.87], [39.459, 41.87], [39.459, 58.617], [29.624, 68.034], [14.13, 68.034], [4.295, 58.404], [4.295, 41.87], [-48.875, 41.87], [-58.719, 32.445], [-58.719, 24.076], [-53.904, 10.883], [1.568, -58.608], [19.576, -68.034], [28.363, -68.034], [39.459, -57.142], [39.459, 16.747], [48.876, 16.747]], c: true,
          },
          ix: 2,
        },
        nm: 'Caminho 2',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'mm', mm: 1, nm: 'Mesclar caminhos 1', mn: 'ADBE Vector Filter - Merge', hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [67.999, 69.974], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 4',
      np: 5,
      cix: 2,
      bm: 0,
      ix: 4,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [-1.672, 0], [0, 0], [0, 1.672], [0, 0]], o: [[0, 0], [0, 1.672], [0, 0], [1.672, 0], [0, 0], [0, 0]], v: [[-5.261, -21.097], [-5.261, 18.07], [-2.234, 21.097], [2.234, 21.097], [5.261, 18.07], [5.261, -21.097]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [226.012, 116.911], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 5',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 5,
      mn: 'ADBE Vector Group',
      hd: false,
    }, {
      ty: 'gr',
      it: [{
        ind: 0,
        ty: 'sh',
        ix: 1,
        ks: {
          a: 0,
          k: {
            i: [[0, 0], [0, 0], [-1.672, 0], [0, 0], [0, 1.672], [0, 0]], o: [[0, 0], [0, 1.672], [0, 0], [1.672, 0], [0, 0], [0, 0]], v: [[-5.261, -21.097], [-5.261, 18.07], [-2.234, 21.097], [2.234, 21.097], [5.261, 18.07], [5.261, -21.097]], c: false,
          },
          ix: 2,
        },
        nm: 'Caminho 1',
        mn: 'ADBE Vector Shape - Group',
        hd: false,
      }, {
        ty: 'st', c: { a: 0, k: [0, 0, 0, 1], ix: 3 }, o: { a: 0, k: 100, ix: 4 }, w: { a: 0, k: 0.776, ix: 5 }, lc: 2, lj: 2, bm: 0, nm: 'Traçado 1', mn: 'ADBE Vector Graphic - Stroke', hd: false,
      }, {
        ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Preenchimento 1', mn: 'ADBE Vector Graphic - Fill', hd: false,
      }, {
        ty: 'tr', p: { a: 0, k: [185.124, 116.911], ix: 2 }, a: { a: 0, k: [0, 0], ix: 1 }, s: { a: 0, k: [100, 100], ix: 3 }, r: { a: 0, k: 0, ix: 6 }, o: { a: 0, k: 100, ix: 7 }, sk: { a: 0, k: 0, ix: 4 }, sa: { a: 0, k: 0, ix: 5 }, nm: 'Transformar',
      }],
      nm: 'Grupo 6',
      np: 3,
      cix: 2,
      bm: 0,
      ix: 6,
      mn: 'ADBE Vector Group',
      hd: false,
    }],
    ip: 0,
    op: 450.000018328876,
    st: 0,
    bm: 0,
  }],
  markers: [],
};

export default animationData;
